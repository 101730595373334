import React from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, Card, Tabs, Tag } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";

// Tabs
import UserProfileTab from "./UserProfileTab";
import OrdersTab from "./OrdersTab";
import GamesReceivedTab from "./GamesReceivedTab";
import FatKatInsider from "./FatKatInsider";
import TokkenzTab from "./TokkenzTab";
import IndeigogoTab from "./IndeigogoTab";
import { COLORS } from "../../../constants/actionTypes";

import {
  giftItemCalculations,
  resetGamePersonalizations
} from "../../../appRedux/actions/personalization";

class AppUserPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      profileData: null,
      userDetails: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer ".concat(authUser.token),
        },
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id,
      });

      INSTANCE.post("app-user/get-profile", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.setState({
              isLoading: false,
              profileData: response.data.data,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
        });

      if (authUser) {
        let inputJson = {
          userId: this.props.match.params.id
        }
        this.props.giftItemCalculations(inputJson);
      }
    }
  }

  onChange = (key) => {
    console.log(key);
  };

  render() {
    const { isLoading, profileData } = this.state;
    const { giftCalculation } = this.props.personalizationData;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/customers/list" className="gx-link">
                  Customer Accounts
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="sumission-title">
              {profileData?.firstName} {profileData?.lastName}
            </span>
            <Tag
              style={{ marginLeft: "20px" }}
              color={profileData?.isDeleted ? COLORS.RED : COLORS.LIGHT_GREEN}
            >
              {profileData?.isDeleted ? "Deleted" : "Active"}
            </Tag>
          </Col>
        </Row>
        {isLoading ? (
          <div className="gx-loader-view">
            <CircularProgress />
          </div>
        ) : (
          <Card className="card-margin">
            <Tabs
              defaultActiveKey="1"
              onChange={() => this.onChange}
              type="card"
              items={[
                {
                  label: `Profile`,
                  key: "1",
                  children: <UserProfileTab profileData={profileData} />,
                },
                {
                  label: `Orders`,
                  key: "2",
                  children: <OrdersTab userId={profileData._id} />,
                },
                {
                  label: `Games Received`,
                  key: "3",
                  children: <GamesReceivedTab userId={profileData._id} />,
                },
                {
                  label: `Tokkenz`,
                  key: "4",
                  children: <TokkenzTab profileData={profileData} giftCalculation={giftCalculation} />
                },
                {
                  label: `FatKat INSIDER`,
                  key: "5",
                  children: <FatKatInsider profileData={profileData} />,
                },
                {
                  label: `INDIEGOGO`,
                  key: "6",
                  children: <IndeigogoTab profileData={profileData} />,
                },
                {
                  label: `sideHustle`,
                  key: "7",
                  children: `Content of sideHustle Tab`,
                },
                {
                  label: `KeyZone`,
                  key: "8",
                  children: `Content of KeyZone Tab`,
                },
                {
                  label: `Guild`,
                  key: "9",
                  children: `Content of Guild Tab`,
                },
              ]}
            />
          </Card>
        )}
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    personalizationData: state.personalizationData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    giftItemCalculations,
    resetGamePersonalizations
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AppUserPreview);
