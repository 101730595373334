import React, { Component } from "react";
import {
  Button,
  Breadcrumb,
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  addTokkenzType,
  updateTokkenzType,
  resetTokkenzType,
} from "./../../../appRedux/actions/tokkenzType";

const FormItem = Form.Item;
const { Option } = Select;

class AddTokkenzType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: false,
      types: [
        {
          name: "Gift Items",
        },
        {
          name: "Gift Cards",
        },
        {
          name: "VISA Cards",
        },
      ],
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { authUser } = this.props.auth;

    if (this.props.match.params.id) {
      const INSTANCE = axios.create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer ".concat(authUser.token),
        },
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id,
      });

      INSTANCE.post("tokkenz-type/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              name: response.data.data.name,
              value: response.data.data.value,
              description: response.data.data.description,
            });
            this.setState({
              editId: this.props.match.params.id,
            });
          }
        })
        .catch((error) => {});
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.tokkenzTypeData) {
      if (nextProps.tokkenzTypeData.messageId === 200) {
        notification["success"]({
          message: "Success",
          description: nextProps.tokkenzTypeData.alertMessage,
        });
        nextProps.resetTokkenzType();
        nextProps.history.goBack();
      } else if (nextProps.tokkenzTypeData.messageId === 203) {
        notification["error"]({
          message: "Alert!!",
          description: nextProps.tokkenzTypeData.alertMessage,
        });
        nextProps.resetTokkenzType();
      }
      return true;
    }
    return null;
  }

  onFinish = (values) => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = {
      name: values.name,
      value: values.value,
      description: values.description,
    };

    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateTokkenzType(authUser.token, inputJSON);
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addTokkenzType(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.tokkenzTypeData;
    const { editId, types } = this.state;

    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">
                  Dashboard
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/tokkenz-types/list" className="gx-link">
                  Tokkenz Types
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit" : "Add"}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Tokkenz Type" : "Add Tokkenz Type"} />
        <Row className="row-margin2">
          <Col>
            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              <Card>
                <Form
                  name="tokkenzForm"
                  onFinish={this.onFinish}
                  layout={"vertical"}
                  ref={this.formRef}
                >
                  <FormItem
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please select name",
                      },
                    ]}
                  >
                    <Select placeholder="Select Name">
                      {types.map((item) => (
                        <Option key={item.name}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem
                    label="Value (Tokkenz)"
                    rules={[{ required: true, message: "Please enter value." }]}
                    name="value"
                  >
                    <InputNumber
                      min={0}
                      defaultValue={0}
                      style={{ width: "100%" }}
                    />
                  </FormItem>

                  <FormItem label="Description" name="description">
                    <Input.TextArea placeholder="Description" rows={4} />
                  </FormItem>

                  <FormItem>
                    <Button
                      loading={loader}
                      className="gx-mb-0"
                      type="primary"
                      htmlType="submit"
                    >
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            )}
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    tokkenzTypeData: state.tokkenzTypeData,
  };
}

function mapDispathToProps(dispatch) {
  return bindActionCreators(
    {
      addTokkenzType,
      updateTokkenzType,
      resetTokkenzType,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispathToProps)(AddTokkenzType);
