import { APP_USER } from "../../constants/actionTypes";
import axios from 'axios';

export const listAppUser = (token, data) => {
  return (dispatch) => {
    dispatch({ type: APP_USER.LIST_APP_USER_REQUEST });
    const AuthStr = 'Bearer '.concat(token);
    let inputJSON = JSON.stringify(data);
    const INSTANCE = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Authorization': AuthStr
      }
    });

    INSTANCE.post("app-user/list", inputJSON)
      .then(function (response) {
        dispatch(listAppUserSuccess(response.data));
      })
      .catch(function (error) {
        dispatch(listAppUserError(error.message));
      });
  };
};

const listAppUserSuccess= (data) => ({
    type: APP_USER.LIST_APP_USER_SUCCESS,
    payload: data
});

const listAppUserError = errorMessage => ({
    type: APP_USER.LIST_APP_USER_FAIL,
    payload: errorMessage
});

export const searchAppUser = (data, token) => {
    return (dispatch) => {
        dispatch({ type: APP_USER.SEARCH_APP_USER_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });
        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/search-user-by-admin", inputJSON)
            .then(function (response) {
                dispatch(searchAppUserSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(searchAppUserError(error.message));
            });
    };
};

const searchAppUserSuccess= (data) => ({
    type: APP_USER.SEARCH_APP_USER_SUCCESS,
    payload: data
});

const searchAppUserError = errorMessage => ({
    type: APP_USER.SEARCH_APP_USER_FAIL,
    payload: errorMessage
});

export const getAppUser = (token, data) => {
    return (dispatch) => {
        dispatch({ type: APP_USER.GET_APP_USER_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        INSTANCE.post("app-user/get-by-id", inputJSON)
            .then(function (response) {
                dispatch(getUserSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(getUserError(error.message));
            });
    };
};

const getUserSuccess = (data) => ({
    type: APP_USER.GET_APP_USER_SUCCESS,
    payload: data
});

const getUserError = errorMessage => ({
    type: APP_USER.GET_APP_USER_FAIL,
    payload: errorMessage
});

export const deleteUser = (token, data) => {
    return (dispatch) => {
        dispatch({ type: APP_USER.DELETE_APP_USER_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("app-user/delete", inputJSON)
            .then(function (response) {
                dispatch(deleteUsersSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(deleteUsersError(error.message));
            });
    };
};

const deleteUsersSuccess= (data) => ({
    type: APP_USER.DELETE_APP_USER_SUCCESS,
    payload: data
});

const deleteUsersError = errorMessage => ({
    type: APP_USER.DELETE_APP_USER_FAIL,
    payload: errorMessage
});

export const updateUser = (token, data) => {
    return (dispatch) => {
        dispatch({ type: APP_USER.UPDATE_APP_USER_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);

        INSTANCE.post("app-user/update", inputJSON)
            .then(function (response) {
                dispatch(updateAppUsersSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(updateAppUsersError(error.message));
            });
    };
};

const updateAppUsersSuccess = (data) => ({
    type: APP_USER.UPDATE_APP_USER_SUCCESS,
    payload: data
});

const updateAppUsersError = errorMessage => ({
    type: APP_USER.UPDATE_APP_USER_FAIL,
    payload: errorMessage
});

export const verifyUser = (token, data) => {
    return (dispatch) => {
        dispatch({ type: APP_USER.VERIFY_APP_USER_REQUEST });
        const AuthStr = 'Bearer '.concat(token);
        const INSTANCE = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'Authorization': AuthStr
            }
        });

        let inputJSON = JSON.stringify(data);
        
        INSTANCE.post("app-user/verify", inputJSON)
            .then(function (response) {
                dispatch(verifyUsersSuccess(response.data));
            })
            .catch(function (error) {
                dispatch(verifyUsersError(error.message));
            });
    };
};

const verifyUsersSuccess= (data) => ({
    type: APP_USER.VERIFY_APP_USER_SUCCESS,
    payload: data
});

const verifyUsersError = errorMessage => ({
    type: APP_USER.VERIFY_APP_USER_FAIL,
    payload: errorMessage
});

export const resetUser = () => {
    return (dispatch) => {
        dispatch({ type: APP_USER.RESET_APP_USERS})
    }
}
