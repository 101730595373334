import React, { Component } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, notification, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import * as Yup from 'yup';

import CircularProgress from "components/CircularProgress";
import PageHeader from "components/PageHeader";

import Auxiliary from "util/auxiliary";

import {
  getAppUser,
  resetUser,
  updateUser
} from "./../../../appRedux/actions/appUsers";

const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string().matches(/^\d{10}$/, 'Invalid mobile phone').required('Required').min(10, 'Invalid mobile phone').max(10, 'Invalid mobile phone'),
  email: Yup.string().email('Invalid email').required('Required')
});

class AddAppUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      user: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      }
    }
  };

  componentDidMount() {
    const { authUser } = this.props.auth;
    if (this.props.match.params.id) {
      let inputJSON = {
        _id: this.props.match.params.id
      }
      this.props.getAppUser(authUser.token, inputJSON);
      this.setState({
        editId: this.props.match.params.id
      })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.appUsers) {
      if (nextProps.appUsers.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.appUsers.alertMessage
        });
        nextProps.resetUser();
        nextProps.history.goBack();
      } else if (nextProps.appUsers.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.appUsers.alertMessage
        });
        nextProps.resetUser();
      } else if (nextProps.appUsers.messageId === 202) {
        let userData = nextProps.appUsers.appUserData.data;
        const user = {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          email: userData.email
        };
        nextProps.resetUser();
        return {
          user: user
        }
      }
    }

    return null;
  }

  onFinish = values => {
    const { editId } = this.state;
    const { authUser } = this.props.auth;

    if (editId) {
      let inputJSON = values;
      inputJSON._id = editId;
      this.props.updateUser(authUser.token, inputJSON);
    }
  };

  render() {
    const { loader } = this.props.appUsers;
    const { editId, user } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/customers/list" className="gx-link">Customers</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{editId ? "Edit Customer" : null}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title={editId ? "Edit Customer" : null} />
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Formik
                  validationSchema={UserSchema}
                  onSubmit={values => {
                    this.onFinish(values);
                  }}
                  enableReinitialize={true}
                  initialValues={user}>
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                          />
                          {errors.firstName && touched.firstName ? (
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                          />
                          {errors.lastName && touched.lastName ? (
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="formrow">
                        <Col>
                          <Form.Label>Mobile Phone</Form.Label>
                          <Form.Control
                            type="tel"
                            required
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                          />
                          {errors.phone && touched.phone ? (
                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                        <Col>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            required
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          {errors.email && touched.email ? (
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row className="btnrow">
                        <Col>
                          <Button type="submit">
                            {editId ? "Update" : "Submit"}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  )}
                </Formik>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appUsers: state.appUsers
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ getAppUser, updateUser, resetUser }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddAppUser);

