import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppUsersList from "./list";
import AppUserPreview from "./preview";
import AddAppUser from "./add";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/list`} component={AppUsersList} />
    <Route path={`${match.url}/preview/:id`} component={AppUserPreview} />
    <Route path={`${match.url}/edit/:id`} component={AddAppUser} />
    <Route exact path={`${match.url}/`}>
      <Redirect to={`${match.url}/list`} />
    </Route>
  </Switch>
);

export default Main;
